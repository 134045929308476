/* stylelint-disable order/properties-order */

.searchWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 12px;
  padding: 8px 8px 8px 24px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #EBEEF0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);

  &:hover {
    border-radius: 16px;
    border: 1px solid rgba(28, 112, 238, 0.50);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.20), 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  }

  :global {
    /* stylelint-disable */

    .ant-input {
      flex: 1;
      border: none;
      box-sizing: border-box;
      margin: 0;
      padding: 4px 11px;
      font-size: 16px;
      font-weight: 500;

      &::placeholder {
        color: #b8b8b8
      }


    }

    .ant-input:focus {
      box-shadow: none;
      outline: 0;
      background-color: transparent;


    }

    .ant-input::placeholder {
      color: #121212;
    }

    .ant-btn {
      display: flex;
      align-items: center;
      color: #fff;
      border: 0;
      border-radius: 10px;
      height: 40px;
      font-weight: 700;
      padding: 8px 20px;
      font-size: 16px;
      background: #1C70EE;
      box-shadow: none;


      &:hover {
        background-color: #0055D4 !important;
      }

    }
  }
}