.company {
  padding: 12px 0;
  margin-bottom: 16px;
  overflow: hidden;
  background: #fff;
  border: 1.2px solid #F4F5F6;
  border-radius: 12px;

  .logoBg {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 8px;
    background-color: #fff;
    border-radius: 12px;
  }

  .companyName {
    display: -webkit-box;
    justify-content: center;
    margin: 0 36px 6px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #121212;
    text-align: center;

    // text-overflow: ellipsis;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
  }

  .logo {
    display: block;
    width: 64px;
    height: 64px;
    margin-bottom: 12px;
    background-color: #fff;
    border: 3px solid #F4F5F6;
    border-radius: 12px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  .companyInfo {
    width: 258px;
    padding: 10px 12px;
    margin: auto;
    font-size: 16px;
    color: #121212;
    border-top: 1px solid #F4F5F6;
    border-bottom: 1px solid #F4F5F6;

  }

  .infoPara {
    display: flex;
    align-items: center;

    // padding-left: 4px;
    margin-bottom: 8px;
    line-height: 24px;
  }

  .infoPara svg {
    margin-right: 16px;
  }

  .infoPara span {
    flex: 1;
  }

  .viewJobBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 22px auto 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #121212;
    text-align: center;

    p {
      display: flex;
      align-items: center;
      cursor: pointer;



      /* stylelint-disable-next-line no-descending-specificity */

      span {
        margin-right: 5px;
      }
    }

    p:hover {
      color: #1C70EE;

      svg path {
        stroke: #1C70EE;
      }
    }

  }
}