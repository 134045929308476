.mainSection {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 12px;
}



.pubDate {
  padding: 8px 0 18px 40px;
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}