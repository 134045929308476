.location {
  padding: 28px 40px 20px;
  background-color: #fff;

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px
  }

  .address {
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #3B3C3C;
  }

  .distance {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    svg {
      margin-right: 8px;
      ;
    }
  }

  .viewMap {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #2378E5;


    svg {
      margin-right: 6px;
    }
  }


  :global {
    .workLocalHeadTitle {
      color: red
    }

    #map {
      border-radius: 16px;
    }
  }
}