.recruiter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 18px;
  margin: 0 40px;
  background-color: #F2F9F7;
  border-radius: 12px;

  .avatar {
    position: relative;
    width: 56px;
    height: 56px;
    margin-right: 16px;
    cursor: pointer;
    border: 1.5px solid #fff;
    border-radius: 50%;

    :global {
      .ant-image-mask {
        z-index: 11;
        text-indent: -99990em;
        background: transparent;
      }
    }

    // #3B3C3C

    .cover {
      position: absolute;
      inset: 0;
      background-image: url('./../../images/avatar-offline.png');
      background-size: 100% 100%;
    }

    .online {
      background-image: url('./../../images/avatar-online.png');
    }

    .circle {
      position: absolute;
      right: -2px;
      bottom: 0;
      z-index: 10;
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      background-color: #34C759;
      border: 2px solid #fff;
      border-radius: 50%;
    }
  }

  .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }


  .info {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 20px;
    overflow: hidden;

    p {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 14px;
      line-height: 22px;
      color: #7D7D7D;

      // overflow: hidden;

      // text-overflow: ellipsis;
      // white-space: nowrap;

      &:nth-child(2) {
        margin-top: 3px;
      }
    }

    .activeDate {
      display: inline-flex;
      align-items: center;
      margin-top: 4px;
      margin-right: 15px;
      font-size: 14px;
      line-height: 22px;
      color: #646464;

      svg {
        margin-right: 6px;
      }
    }

    .rateWrap {
      display: flex;
      flex: 1;
      align-items: center;

    }

    .rate {
      box-sizing: content-box;
      display: flex;
      align-items: center;
      height: 18px;
      padding: 2px 8px 2px 4px;
      font-size: 12px;
      line-height: 18px;
      color: #515151;
      white-space: nowrap;
      background: #F5FAFB;
      border-radius: 4px;

      svg {
        margin-right: 4px;
      }
    }


    .name {
      margin-right: 16px;
      overflow: hidden;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: #121212;
      text-overflow: ellipsis;
      white-space: nowrap;

      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;

    }

    .companyName {
      // max-width: 48%;
      // margin-right: 8px;

      // overflow: hidden;
      font-size: 16px;
      color: #3B3C3C;

      // text-overflow: ellipsis;
      // white-space: nowrap;
      svg {
        position: relative;
        top: -3px;
        margin: 0 10px;
      }
    }

    .jobTitle {
      max-width: 48%;
      overflow: hidden;
      font-size: 16px;
      line-height: 23px;
      color: #3B3C3C;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
  }



  :global {

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-btn {
      box-sizing: content-box;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      padding: 0 32px;
      font-size: 16px !important;
      font-weight: 700;
      color: #000 !important;
      background: #FFF !important;
      border: 1px solid #fff;
      border-radius: 10px;
      box-shadow: none;
      transition: none !important;

      &:hover {
        color: #000 !important;
        background: #fff !important;
        border: 1px solid rgba(0, 102, 255, 0.50) !important;
        box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.10) !important;
      }
    }


    /* stylelint-disable-next-line selector-class-pattern */
    .ant-btn-primary[disabled] {
      text-shadow: none;
      background: #f5f5f5;
      border-color: #d9d9d9;
      box-shadow: none;
    }
  }
}