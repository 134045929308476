.reminder {
  padding: 20px 24px 32px;
  background: #F5F9FF;
  border-radius: 12px;

  .svg {
    display: block;
    margin: 0 auto;
  }

  h4 {
    margin: 8px 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #4B4C4D;
    text-align: center;

  }

  p,
  li {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #515151;
  }

  p {
    margin-bottom: 16px;


  }

  .link {
    color: #515151;
    text-decoration: underline;
    cursor: pointer;
  }

  li {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    list-style: none;

    svg {
      margin: 11px 8px 0 0;
    }


    span {
      flex: 1;
    }
  }
}