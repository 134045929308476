.signUp {
  padding: 16px 20px;
  margin-bottom: 16px;
  background-color: #F2F8FB;
  border-radius: 12px;

  h3 {
    margin-bottom: 17px;
    font-size: 16px;
    font-weight: 600;
    color: #121212;
    text-align: center;
  }

  :global {

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-btn {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 44px;
      padding: 12px 16px;
      margin: 12px 0 0;
      font-size: 16px;
      font-weight: 700;
      color: #121212;
      background: #fff;
      border: none;
      border: 1px solid #EBEEF0;
      border-radius: 8px;
      box-shadow: none
    }
  }
}