.reportJob {
  display: flex;
  justify-content: center;
  padding: 8px 0;

  // margin: 6px 0;
  font-size: 14px;
  line-height: 22px;
  color: #7D7D7D;
  cursor: pointer;

  &:hover {
    color: #2378E5;

  }

  &:hover svg path {
    stroke: #2378E5
  }

  div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  svg {
    margin-right: 6px;
  }
}