/* stylelint-disable selector-max-class */
.drawerFilter {
  width: 100%;
  background: #fff;
  border-radius: 16px 0 0 16px;

  .w100 {
    width: 100%;
  }

  :global {
    .ant-drawer-body {
      padding: 0 !important;
    }

    .ant-drawer-close {
      width: auto !important;
      height: auto !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 26px;
      color: var(--text-icon-07121212, #111212) !important;
    }

    .ant-checkbox-wrapper {
      padding: 11px 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #111212;
    }

    .ant-checkbox .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border: 1.6px solid var(--line-border-04-e-2-e-3-e-4-quaternary, #dbdfe4);
      border-radius: 6px;
    }

    .ant-checkbox .ant-checkbox-inner::after {
      inset-inline-start: 30%;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1c70ee !important;
      border-color: #1c70ee !important;
    }

    .ant-checkbox-wrapper-checked {
      color: #1c70ee !important;
    }

    .ant-checkbox {
      position: relative;
      top: 3px;
      align-self: flex-start;
    }

    .selectLabelPlaceholder,
    .selectLabel {
      font-weight: 400 !important;
      color: #646566 !important;
      text-align: left !important;
    }

    .ant-anchor-link {
      min-height: 40px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #3b3c3c;
    }

    .ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink {
      width: 3px;
      min-height: 40px !important;
    }

    .ant-anchor-wrapper .ant-anchor .ant-anchor-link-active > .ant-anchor-link-title {
      font-weight: 700;
    }
  }

  ul {
    list-style: none;
  }

  .container {
    display: flex;
    gap: 16px;
  }

  .menu {
    width: 224px;
    padding-top: 6px;
  }

  .menuItem {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    align-self: stretch;
    min-height: 40px;
    padding: 4px 8px 4px 16px;
    margin-top: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #3b3c3c;
    cursor: pointer;
    border-left: 3px solid #fff;
    transition: 0.3s;

    &:hover {
      color: #1c70ee;
    }

    &.active {
      font-weight: 700;
      color: #1c70ee;
      border-left: 3px solid #1c70ee;
      transition: 0.3s;
    }
  }

  .main {
    flex: 1;
    height: calc(100vh - 70px);
    padding: 24px;
    padding-bottom: 400px;
    overflow-y: auto;
    background: #f8f9fa;
  }

  .mainBgColour {
    padding-bottom: 0;
    background: #fff;
  }

  .selectItem {
    margin-bottom: 32px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #111212;
      text-align: center;
    }

    :global {
      .ant-select {
        .ant-select-selector {
          box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1) !important;
        }
      }
    }
  }

  .selectItem .title span {
    cursor: pointer;
  }

  .selectItem .shrink {
    transform: rotate(180deg);
  }

  .chooseItem {
    display: flex;
    flex-wrap: wrap;

    :global {
      .ant-select {
        .ant-select-selector {
          box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1) !important;
        }
      }
    }
  }
}

.applyFilterBtn {
  padding: 0 16px;
}
