/* stylelint-disable font-family-no-missing-generic-family-keyword */
.companyCard {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  border: 1px solid #EBEEF0;
  border-radius: 16px;
  box-shadow: 0 8px 14px -2px rgba(162, 183, 203, 0.15);

  &:hover {
    border-color: rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px var(--global-alpha-brand-brand-10, rgba(0, 102, 255, 0.1));
  }
}

.topRoot {
  display: flex;
  flex-direction: column;

  // gap: 16px;
  padding: 20px 20px 24px;

  .logoRow {
    display: flex;
    gap: 16px;

    // align-items: center;
  }

  .logo {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    background: #fff;
    border: 1.5px solid var(--line-border-02-f-4-f-5-f-6-secondary, #f4f5f6);
    border-radius: 10px;
  }

  .industryWrapper {
    // display: flex;
    // gap: 8px;
    // align-items: center;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .dot {
      position: relative;
      display: inline-block;
      width: 18px;

      &::before {
        position: absolute;
        top: -5px;
        left: 8px;
        width: 2px;
        height: 2px;
        content: " ";
        background-color: #000;
        border-radius: 50%;
      }
    }
  }

  .industryItem {
    /* WEB/Body/Body1-regular */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    /* 150% */
    color: var(--text-icon-054-b-4-c-4-d, #4b4c4d);
  }

  .name {
    display: -webkit-box;
    overflow: hidden;

    /* WEB/Heading/H5 */
    font-family: "SF Pro Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    /* 140% */
    color: #000;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;

    /* 限制为两行 */
    -webkit-box-orient: vertical;
  }

  .desc {
    display: -webkit-box;
    height: 48px;
    margin-top: 8px;
    overflow: hidden;

    /* WEB/Heading/Subtitle3-medium */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    /* 150% */
    color: #000;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;

    /* 限制为两行 */
    -webkit-box-orient: vertical;

    &.defaultDesc {
      color: #acaeb1;
    }
  }

  .tagRoot {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: 36px;

    // padding-top: 1px;
    margin-top: 16px;
    overflow: hidden;
  }

  .tagItem {
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 6px 10px;
    line-height: 24px;
    border: 1.2px solid var(--line-border-03-eeeff-0-tertiary, #ebeef0);
    border-radius: 8px;
  }
}

.bottomRoot {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  padding: 0 20px;
  margin-top: auto;
  border-top: 1px solid var(--global-alpha-black-black-5, rgba(0, 0, 0, 0.05));

  .jobOpeningTag {
    /* WEB/Heading/Subtitle4-medium */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #515151;
  }

  .numberFlag {
    /* WEB/Heading/H6 */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    /* 150% */
    color: #1c70ee;
  }

  .viewMore {
    /* WEB/Heading/Subtitle4-medium */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    /* 157.143% */
    color: #000;
  }
}